import { useEffect } from 'react';
import { Grid, Layout, Row } from 'antd';
import classNames from 'classnames';

import { Title } from 'src/components/Layout/Typography';
import { useKlikStoreCategories, useKlikStoreMasterProducts } from 'src/hooks';
import { FloorSection, IKlikStoreMasterProduct } from 'src/interface';
import { googleSelectItemEvent, googleViewItemListEvent } from 'src/utils/func';

import KlikStoreProductFloor from '../../Products/ProductFloor';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface FloorTypeStoreProductProps {
	className?: string;
	floorData: FloorSection;
	title: string;
	seeAllSlug: string;
	ignorePaddingX?: boolean;
	sort?: string;
	dir?: string;
}

const FloorTypeStoreProduct = ({
	className = '',
	ignorePaddingX = false,
	sort,
	dir,
	...props
}: FloorTypeStoreProductProps) => {
	const { xs } = Grid.useBreakpoint();

	const dataCategories: any = useKlikStoreCategories();
	const categoryTree = dataCategories?.data?.data?.records;

	const data: any = useKlikStoreMasterProducts({
		category: props.floorData?.slug,
		limit: 12,
		sort,
		dir,
	});
	const productList = data?.data?.data?.records;

	const onProductClick = (item: IKlikStoreMasterProduct) => {
		// push google analytics
		googleSelectItemEvent({
			...item,
			itemCategory: ['KALStore', ...(item?.breadcrumbs?.map(({ name }) => name) ?? [])],
			itemListName: props.title,
		});
	};

	useEffect(() => {
		// push google analytics
		if (productList?.length > 0 && categoryTree?.length > 0) {
			googleViewItemListEvent(
				productList?.map((record: IKlikStoreMasterProduct) => {
					return {
						...record,
						itemCategory: ['KALStore', ...(record?.breadcrumbs?.map(({ name }) => name) ?? [])],
					};
				}) ?? [],
				'KALStore',
				props.title,
			);
		}
	}, [productList, categoryTree]);

	if (productList?.length === 0) return null;

	return (
		<Layout.Content className={className}>
			<div
				className={classNames(
					{ container: !xs && !ignorePaddingX },
					{ 'px-3': xs && !ignorePaddingX },
				)}
			>
				<Row justify="space-between" className="align-items-baseline mb-3">
					<Title
						level={2}
						className="fs-14 fs-md-14 fs-lg-15 fs-xl-16 fw-600 mb-0"
						text={props.title}
					/>
					<a href={props.seeAllSlug} className="fs-13 fs-md-13 fs-lg-14 fs-xl-15 fw-600">
						Lihat Semua
					</a>
				</Row>
				{productList?.length > 0 && (
					<KlikStoreProductFloor productList={productList} onProductClick={onProductClick} />
				)}
			</div>
		</Layout.Content>
	);
};

export default FloorTypeStoreProduct;
