import { useEffect, useState } from 'react';
import type { RootStateOrAny } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useAsync } from 'react-use';
import { useRouter } from 'next/router';

import ServiceSliderC from 'src/components/Carousel/HealthServicesCategory/ServiceSlider';
import ArticleSectionC from 'src/components/Home/Article';
import KlikStoreHome from 'src/components/Home/KlikStore';
import MainSectionC from 'src/components/Home/Main';
import PromoSectionC from 'src/components/Home/Promo';
import { useVouchersListHomepage } from 'src/hooks/useVoucher';
import { Articles, ICategoryByGroup } from 'src/interface';
import {
	actionGetArticleByCategorySlug,
	actionGetCategoryHomePage,
	actionUserProfile,
} from 'src/redux/actions';
import { trackInsiderObject } from 'src/utils/analytics/insider';
import AuthStorage from 'src/utils/auth-storage';
import authStorage from 'src/utils/auth-storage';

import classes from './home.module.less';

const Home = () => {
	const { homepage, menu, categoryByGroup, articleByGroup } = useSelector(
		(state: RootStateOrAny) => state,
	);
	const dataMenu = menu.data?.record?.layanan_menu || [];
	const dataAds = homepage.banner?.data?.record?.ads?.ads || [];
	const { data: listDataPromos, isSuccess: successDataPromo } = useVouchersListHomepage(5);
	const listDataPromo = listDataPromos?.data;

	const { categoryGroups } = categoryByGroup;
	const articleCategories: ICategoryByGroup[] = categoryGroups;

	const { articleByCategory }: any = articleByGroup;
	const totalArticle = articleByCategory?.meta?.pagination?.total_records;
	const articles: Articles[] = articleByCategory?.data?.records;

	const dispatch = useDispatch();
	const router = useRouter();
	const [category, setCategory] = useState<string>(AuthStorage.loggedIn ? 'pilihan' : '');
	const [tabActive, setTabActive] = useState<string>(
		AuthStorage.loggedIn ? '1__pilihan' : `1__${articleCategories?.[0]?.slug}`,
	);

	const onChangeTab = (e: any) => {
		const key = e?.key || e;
		setTabActive(key);
		setCategory(key?.split('__')[1]);
		if (key.includes('semua-kategori')) {
			router.push('/semua-kategori');
		}
	};

	const getArticle = async () => {
		if (category === 'pilihan') {
			dispatch(await actionGetArticleByCategorySlug('mytopics'));
		} else if (category !== 'semua-kategori') {
			dispatch(
				await actionGetArticleByCategorySlug(category, {
					page: 1,
					per_page: 5,
				}),
			);
		}
	};

	useEffect(() => {
		if (category) getArticle();
	}, [category]);

	useAsync(async () => {
		if (AuthStorage.loggedIn) {
			await dispatch(await actionGetCategoryHomePage('mytopics'));
			dispatch(await actionUserProfile(authStorage.tokenDecode.id));
		}
	}, [AuthStorage.loggedIn]);

	useEffect(() => {
		trackInsiderObject('page')({
			type: 'Home',
		});
	}, []);

	return (
		<div className={classes.wrapper}>
			<MainSectionC items={dataMenu} />

			<PromoSectionC listDataPromo={listDataPromo} successDataPromo={successDataPromo} />

			{/* KlikStore Info Home */}
			<KlikStoreHome />
			{/* End KlikStore Info Home */}

			<ServiceSliderC />

			<ArticleSectionC
				categoryTopic={articleCategories}
				articles={articles}
				onChange={onChangeTab}
				activeKey={tabActive}
				ads={dataAds}
				totalArticle={totalArticle}
				showLoadmore={
					!['pilihan', 'latest'].includes(tabActive?.split('__')[1]) && totalArticle > 4
				}
			/>
		</div>
	);
};

export default Home;
