import FloorTypeStoreProduct from 'src/components/KlikStore/Landing/FloorTypeStoreProduct';
import { useKlikStoreCategoryDetail } from 'src/hooks';

import classes from './Floor.module.less';

const DEFAULTCATEGORYSLUG = `${process.env.NEXT_PUBLIC_HOME_MP_CATEGORY_SLUG}`;

const Floor = () => {
	const { data: resData } = useKlikStoreCategoryDetail(DEFAULTCATEGORYSLUG);
	const catData: any = resData?.data?.record;
	return (
		<div className="bg-white">
			<div className={classes['wrap-title']}>
				<h3 className={classes['shop-klikstore-title']}>Belanja di KALStore</h3>
			</div>
			<FloorTypeStoreProduct
				floorData={catData}
				title={catData?.name || ''}
				seeAllSlug={'/kalstore'}
				ignorePaddingX={true}
				sort="sold"
			/>
		</div>
	);
};

export default Floor;
