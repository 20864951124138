import { useEffect, useState } from 'react';
import { Col, Grid, Row } from 'antd';
import classNames from 'classnames';

import PromoCarouselC from 'src/components/Home/Promo/PromoCarousel';
import TagC from 'src/components/Tag';
import { PromoHomepageProps } from 'src/interface';

import classes from './index.module.less';

type Props = {
	listDataPromo: any;
	successDataPromo: boolean;
};

const typePromo = [
	{ id: 1, type: 'all', title: 'Semua' },
	{ id: 2, type: 'internal_promotions', title: 'Promo' },
	{ id: 3, type: 'partner_promotions', title: 'Kerja Sama' },
];

const PromoSectionC = (props: Props) => {
	const { listDataPromo, successDataPromo } = props;
	const dataPromo: PromoHomepageProps[] = listDataPromo?.internal_promotions || [];
	const dataKerjasama: PromoHomepageProps[] = listDataPromo?.partner_promotions || [];
	const { xs, sm, lg } = Grid.useBreakpoint();
	const [promoActive, setPromoActive] = useState<number>(1);
	const [promoData, setPromoData] = useState<any[]>([...dataPromo, ...dataKerjasama]);
	const [promoHide, setPromoHide] = useState<boolean>(false);
	const [promoType, setPromoType] = useState<any[]>(typePromo);

	const promoFilter = (id: number, type: string) => {
		setPromoHide(true);
		setPromoActive(id);

		if (type === 'all') {
			setPromoData([...dataPromo, ...dataKerjasama]);
		} else {
			setPromoData(listDataPromo[type]);
		}

		setTimeout(() => {
			setPromoHide(false);
		}, 0);
	};

	useEffect(() => {
		if (listDataPromo) {
			promoFilter(promoActive, typePromo.find(({ id }) => id === promoActive)?.type!);
		}

		if (dataPromo.length === 0) {
			setPromoType((prevState: any) => {
				delete prevState[1];
				return prevState;
			});
		}
		if (dataKerjasama.length === 0) {
			setPromoType((prevState: any) => {
				delete prevState[2];
				return prevState;
			});
		}
	}, [successDataPromo, listDataPromo]);

	if (promoData.length === 0) {
		return null;
	}
	return (
		<div className={classes.promo}>
			<div className="container">
				<Row
					align="middle"
					justify={(xs || sm) && !lg ? 'start' : 'center'}
					className={classes.tags}
				>
					<Col>
						{promoType.map((item, i: number) => (
							<TagC
								key={i}
								type="chips"
								className={classNames(classes.tag, { [classes.active]: promoActive === item.id })}
								onClick={() => promoFilter(item.id, item.type)}
							>
								{item.title}
							</TagC>
						))}
					</Col>
				</Row>
				{!promoHide && <PromoCarouselC items={promoData} slice={promoActive === 1 ? 10 : 5} />}
			</div>
		</div>
	);
};

export default PromoSectionC;
