/* eslint-disable @next/next/no-html-link-for-pages */
import Slider from 'react-slick';
import { Col, Grid, Row, Typography } from 'antd';

import ImageC from 'src/components/Image';
import { NextArrow, PrevArrow } from 'src/components/Layout/Slick/Arrow';
import { HEALTH_TYPE } from 'src/constants/services';
import { useHealthServicesCategoryService } from 'src/hooks';
import { getAppointmentImg } from 'src/utils/func';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classes from './index.module.less';

interface Hit {
	document: Document;
	highlights: any[];
	text_match: number;
	itemKey?: number;
}

interface Document {
	id: string;
	name: string;
	photo_highres: string;
	slug: string;
}

const { Paragraph, Text } = Typography;

const ServiceSliderC = () => {
	const { xs, sm, md, lg } = Grid.useBreakpoint();
	const responsive = (xs || sm) && !md;

	const slidesToShowMid = md && !lg ? 5 : 6;
	const slidesToShow = responsive ? 3.1 : slidesToShowMid;

	const imageHeightMid = md && !lg ? 98 : 170;
	const imageHeight = responsive ? 95 : imageHeightMid;

	const imageWidthMid = md && !lg ? 90 : 157;
	const imageWidth = responsive ? 88 : imageWidthMid;

	const { data, isLoading, isError } = useHealthServicesCategoryService();
	const items = data?.results?.[0].hits;
	const isEmpty = !isLoading && items ? items?.length === 0 : true;

	const ServiceSliderContentC = (documents: Hit) => {
		const item = documents.document;
		const key = Number(documents?.itemKey || 0) + 1;
		const isEven = key % 2 === 0;
		return (
			<a href={`/layanan/${item.slug}`} className="cursor-pointer">
				<div className={classes.box}>
					{!!item.photo_highres && (
						<div className={isEven ? classes['bg-blue'] : classes['bg-green']} />
					)}
					<ImageC
						priority
						objectFit="cover"
						height={imageHeight}
						width={imageWidth}
						alt={item.name}
						src={item.photo_highres}
						defaultSrc={getAppointmentImg(HEALTH_TYPE.SERVICE, true)}
						className={classes.img}
					/>
				</div>
				<Paragraph className="fs-12 fs-lg-14 fw-600 text-center mt-2 mt-lg-3 mb-0">
					{item.name}
				</Paragraph>
			</a>
		);
	};

	return (
		<div className="container">
			<Row align="middle" justify="space-between" className="my-3 my-sm-4">
				<Col>
					<Text className="fs-14 fs-sm-20 fs-md-24 fw-600">Layanan di KlikDokter</Text>
				</Col>
				<Col>
					<a href="/layanan" className="cursor-pointer">
						<Text className="fs-12 fs-sm-14 fs-md-16 fw-600 text-primary">Lihat Semua</Text>
					</a>
				</Col>
			</Row>

			{!isLoading && !isError && !isEmpty && (
				<div className="mb-4">
					<Slider
						infinite={false}
						slidesToShow={slidesToShow}
						nextArrow={<NextArrow size={32} sizeArrow={16} />}
						prevArrow={<PrevArrow size={32} sizeArrow={16} />}
						className={classes.slider}
					>
						{items.map((item: Hit, i: number) => (
							<ServiceSliderContentC key={i} itemKey={i} {...item} />
						))}
					</Slider>
				</div>
			)}
		</div>
	);
};

export default ServiceSliderC;
