import { useInfiniteQuery, useQuery } from 'react-query';

import { VOUCHER } from 'src/constants/services';
import { fetchApiPromotion } from 'src/utils/fetch-api';

const vouchers = 'vouchers';
const promoList = 'promo_list';
const myVoucherList = 'my_voucher_list';

export const useVouchersListHomepage = (limit: number = 1) => {
	const getList = () => {
		return fetchApiPromotion({
			url: `/public/api/${VOUCHER.service.promo_homepage}?limit=${limit}`,
		});
	};

	return useQuery([vouchers, limit], () => getList(), {
		keepPreviousData: true,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
};

export const usePromoList = (search: string = '') => {
	return useInfiniteQuery(
		[promoList, search],
		({ pageParam = 1 }) => {
			return fetchApiPromotion({
				url: '/api/voucher/public',
				payload: { page: pageParam, limit: 10, code: search },
			});
		},
		{
			refetchOnWindowFocus: false,
			getNextPageParam: (res) =>
				res?.meta.pagination?.page < res?.meta.pagination?.total_page
					? res?.meta.pagination?.page + 1
					: undefined,
		},
	);
};

export const useMyVoucherList = (search: string = '') => {
	return useInfiniteQuery(
		[myVoucherList, search],
		({ pageParam = 1 }) => {
			return fetchApiPromotion({
				url: '/api/voucher/list',
				payload: { page: pageParam, limit: 10, code: search },
			});
		},
		{
			refetchOnWindowFocus: false,
			getNextPageParam: (res) =>
				res?.meta.pagination?.page < res?.meta.pagination?.total_page
					? res?.meta.pagination?.page + 1
					: undefined,
		},
	);
};
