import { Grid } from 'antd';

import ImageC from 'src/components/Image';

import classes from './Banner.module.less';

const KlikStoreBanner = () => {
	const { lg, md, sm, xs } = Grid.useBreakpoint();

	let imageSize: any = {
		width: 371,
		height: 136,
	};

	if (lg || md || sm) {
		imageSize = {
			width: 371,
			height: 136,
		};
	} else if (xs) {
		imageSize = {
			width: 156,
			height: 106,
		};
	}

	return (
		<div className={classes['wrap-ks-banner']}>
			<div className={classes['ks-bg']}>
				<ImageC
					alt="KALStore"
					src={
						imageSize?.width === 371
							? '/images/banners/home/belanja-sehat-banner-desktop.png'
							: '/images/banners/home/belanja-sehat-banner-mobile.png'
					}
					width={imageSize.width}
					height={imageSize.height}
				/>
			</div>
			<div className={classes['ks-detail']}>
				<h3>Belanja keperluan kesehatanmu disini!</h3>
				<a href={'/kalstore'} className={classes['button-link']}>
					Lihat KALStore
				</a>
			</div>
		</div>
	);
};

export default KlikStoreBanner;
