import KlikStoreBanner from './Banner';
import KlikStoreHomeFloor from './Floor';

const KlikStoreHome = () => {
	return (
		<div className="container">
			<KlikStoreHomeFloor />
			<KlikStoreBanner />
		</div>
	);
};

export default KlikStoreHome;
