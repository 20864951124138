import { dehydrate, QueryClient } from 'react-query';
import type { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';

// import ConsultationFloatingWidget from 'src/components/ConsultationFloatingWidget';
import g from 'src/constants/global';
import { TYPESENSE, VOUCHER } from 'src/constants/services';
import Home from 'src/containers/Home';
import { ICategoryByGroup } from 'src/interface';
import wrapper from 'src/redux';
import {
	actionGetArticleByCategorySlug,
	actionGetBanner,
	actionGetCategoryHomePage,
	actionGetDownload,
	actionGetFooterMenu,
	actionGetMenu,
} from 'src/redux/actions';
import AuthStorage from 'src/utils/auth-storage';
import { fetchApiPromotion, fetchApiTypesense } from 'src/utils/fetch-api';

const HeadC = dynamic(() => import('src/components/Head'));
const topics = AuthStorage.loggedIn ? 'mytopics' : 'homepages';

const HomePage = () => {
	return (
		<>
			<HeadC
				title="KlikDokter - Konsultasi Medis, Booking RS dan Pesan Obat"
				description={
					'Konsultasi lewat chat dengan dokter umum dan spesialis, ' +
					'beli obat, vitamin, booking RS, dan cek kesehatan dengan ' +
					'Health Tools. Hidup sehat bersama KlikDokter.'
				}
				hideSuffix
			/>
			<Home />

			{/* <ConsultationFloatingWidget /> */}
		</>
	);
};

export const getStaticProps: GetStaticProps = wrapper.getStaticProps((store) => async () => {
	const queryClient = new QueryClient();

	try {
		await store.dispatch(await actionGetMenu());
		await store.dispatch(await actionGetFooterMenu());
		await store.dispatch(await actionGetBanner());
		await store.dispatch(await actionGetDownload());
		await store.dispatch(await actionGetCategoryHomePage(topics));

		const category: ICategoryByGroup[] = store.getState().categoryByGroup.categoryGroups;
		const slug = category?.[0]?.slug;

		if (slug === 'pilihan') {
			await store.dispatch(await actionGetArticleByCategorySlug('mytopics'));
		} else if (slug !== 'semua-kategori') {
			await store.dispatch(
				await actionGetArticleByCategorySlug(slug, {
					page: 1,
					per_page: 5,
				}),
			);
		}

		await queryClient.prefetchQuery(['vouchers', 5], () =>
			fetchApiPromotion({
				url: `/public/api/${VOUCHER.service.promo_homepage}`,
				payload: { limit: 5 },
			}),
		);

		await queryClient.prefetchQuery(['useHealthServicesCategoryService'], () =>
			fetchApiTypesense({
				url: `/${TYPESENSE.service}`,
				options: { method: 'POST' },
				payload: {
					searches: [
						{
							q: '*',
							query_by: 'name',
							collection: `${TYPESENSE.model.categoryServices}`,
							filter_by: 'parent_uid:0',
							sort_by: 'sort_order:asc',
							include_fields: 'id,name,photo_highres,photo_lowres,slug',
						},
					],
				},
			}),
		);
	} catch (err: any) {
		throw err;
	}

	return {
		props: {
			dehydratedState: dehydrate(queryClient),
			jsonLd: { organization: true },
		},
		revalidate: g.REVALIDATE_EXP,
	};
});

export default HomePage;
