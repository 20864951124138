import Slider from 'react-slick';
import { Col, Grid, Row } from 'antd';

import ImageC from 'src/components/Image';
import { NextArrow, PrevArrow } from 'src/components/Layout/Slick/Arrow';
import { PromoHomepageProps } from 'src/interface';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classes from './PromoCarousel.module.less';

interface PromoCarouselProps {
	items: PromoHomepageProps[];
	slice: number;
}

const PromoCarousel = ({ items, slice }: PromoCarouselProps) => {
	const { xs, sm, md, lg } = Grid.useBreakpoint();
	const responsive = (xs || sm) && !md;

	const imageWidthMid = !lg ? 510 : 590;
	const imageHeightMid = !lg ? 191 : 221;
	const imageWidth = responsive ? 288 : imageWidthMid;
	const imageHeight = responsive ? 108 : imageHeightMid;

	const numberOfPromo = items?.length || 0;

	const ButtonSeeAllC = () => {
		return (
			// eslint-disable-next-line @next/next/no-html-link-for-pages
			<a href="/promo" className="fs-12 fs-md-14 fs-lg-16 fw-600 cursor-pointer text-primary">
				Lihat Semua
			</a>
		);
	};

	return (
		<div className={`${classes.wrapper} text-center`}>
			<Slider
				dots
				infinite
				adaptiveHeight={!responsive}
				centerMode={numberOfPromo > 1}
				variableWidth={numberOfPromo >= 2}
				arrows={!xs}
				nextArrow={xs ? undefined : <NextArrow size={32} sizeArrow={16} />}
				prevArrow={xs ? undefined : <PrevArrow size={32} sizeArrow={16} />}
				className={classes.slider}
				appendDots={(dot: any) => (
					<Row align="middle" justify="space-between">
						<Col>
							<ul>{dot}</ul>
						</Col>
						<Col>
							<ButtonSeeAllC />
						</Col>
					</Row>
				)}
			>
				{items?.slice(0, slice).map((item: PromoHomepageProps, i: number) => (
					<a key={i} href={`/promo/${item.slug}`} className="cursor-pointer">
						<ImageC
							priority
							objectFit="cover"
							alt={item.name}
							src={item.banner_image}
							defaultSrc="/images/voucher/default.svg"
							width={imageWidth}
							height={imageHeight}
						/>
					</a>
				))}
			</Slider>

			{numberOfPromo <= 1 && (
				<Row justify="space-between" className={classes.indicator}>
					<Col>
						<button className={classes.active} />
					</Col>
					<Col>
						<ButtonSeeAllC />
					</Col>
				</Row>
			)}
		</div>
	);
};

export default PromoCarousel;
